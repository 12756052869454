// Generated by Framer (acd6bc0)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["dcoFLRYMS", "lti7Ag8zv"];

const serializationHash = "framer-goN2Z"

const variantClassNames = {dcoFLRYMS: "framer-v-kqj1gp", lti7Ag8zv: "framer-v-d1bcag"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "dcoFLRYMS", "Variant 2": "lti7Ag8zv"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "dcoFLRYMS"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "dcoFLRYMS", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearngsemj = activeVariantCallback(async (...args) => {
await delay(() => setVariant("lti7Ag8zv"), 1800)
})

const onAppear13rvbft = activeVariantCallback(async (...args) => {
await delay(() => setVariant("dcoFLRYMS"), 600)
})

useOnVariantChange(baseVariant, {default: onAppearngsemj, lti7Ag8zv: onAppear13rvbft})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-kqj1gp", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"dcoFLRYMS"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({lti7Ag8zv: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-sgd01q-container"} layoutDependency={layoutDependency} layoutId={"AQuxZMsxr-container"}><Phosphor color={"var(--token-bc567dab-a014-498f-95c5-57b3fd26b32c, rgb(1, 31, 64))"} height={"100%"} iconSearch={"House"} iconSelection={"MagnifyingGlass"} id={"AQuxZMsxr"} layoutId={"AQuxZMsxr"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-goN2Z.framer-tqtlmr, .framer-goN2Z .framer-tqtlmr { display: block; }", ".framer-goN2Z.framer-kqj1gp { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 32px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 32px; }", ".framer-goN2Z .framer-sgd01q-container { flex: none; height: 32px; position: relative; width: 32px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-goN2Z.framer-kqj1gp { gap: 0px; } .framer-goN2Z.framer-kqj1gp > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-goN2Z.framer-kqj1gp > :first-child { margin-left: 0px; } .framer-goN2Z.framer-kqj1gp > :last-child { margin-right: 0px; } }", ".framer-goN2Z.framer-v-d1bcag .framer-sgd01q-container { height: 27px; width: 27px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"lti7Ag8zv":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramercqOEDyzJ1: React.ComponentType<Props> = withCSS(Component, css, "framer-goN2Z") as typeof Component;
export default FramercqOEDyzJ1;

FramercqOEDyzJ1.displayName = "press search button";

FramercqOEDyzJ1.defaultProps = {height: 32, width: 32};

addPropertyControls(FramercqOEDyzJ1, {variant: {options: ["dcoFLRYMS", "lti7Ag8zv"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramercqOEDyzJ1, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})